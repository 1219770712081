<template>
  <v-container>
    <v-card class="pa-0">
      <v-card-title>
        Session List
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="pa-0"
        ></v-text-field>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        dense
        :headers="headers"
        :search="search"
        :items="items.data"
        :server-items-length="items.total"
        :options.sync="pagination"
        :footer-props="{
          'items-per-page-options': [5,10,20,30,50,100,-1],
        }"
        :loading="loading"
        item-key="name"
        class="elevation-1"
      >
      <template v-slot:item.start_time="{item}">
        {{ moment(item.start_time).format('DD-MM-YYYY hh:mm A') }}
      </template>
      <template v-slot:item.end_time="{item}">
        {{ moment(item.end_time).format('DD-MM-YYYY hh:mm A') }}
      </template>
      <template v-slot:item.video="{item}">
        <v-btn color="grey darken-2" small dark @click="play_video = item.meeting_id_url" v-if="!item.is_live_meeting">
          <v-icon>mdi-play</v-icon> Play
        </v-btn>
        <span v-else> - </span>
      </template>
      <template v-slot:item.action="{item}">
        <v-btn color="grey darken-2" icon dark :to="'/session/edit/'+item.session_id">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn color="grey darken-2" icon dark @click="confirmDelete(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
      </v-data-table>
    </v-card>

    <v-btn color="primary" fixed fab bottom right to="/session/add">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <!-- Video play -->
    <v-dialog v-model="play_video" persistent width="50%" max-width="640px" v-if="play_video">
      <v-card>
        <v-card-text class="pa-3 rounded-lg">
          <iframe :src="play_video" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          <!-- <video :src="play_video" width="100%" height="300vh" controls></video> -->
        </v-card-text>
         <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="play_video = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Delete confirmation -->
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-text class="pt-5">Are you sure delete <b>{{session.title}}</b>?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="red darken-4" text @click="deleteItem(session)">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
 
import { parseParams, getAlphabets } from "../../plugins/helper";
export default {
  name: "Sessionlist",
  data() {
    return {
      play_video:false,
      loading: false,
      dialog: false,
      session:{
        title:null
      },
      search: "",
      items: {
        current_page: 1,
        per_page: 50,
        data: [],
      },
      headers: [
        { text: "Title", value: "title" },
        { text: "Vendor Name", value: "vendor_name" },
        { text: "Start Time", value: "start_time" },
        { text: "End Time", value: "end_time" },
        { text: "Video", value: "video" },
        { text: "Action", value: "action", sortable: false },
      ],
      pagination: {
        itemsPerPage: 50,
        sortBy: ["created_at"],
        page: 1,
        filters: {
          item_active: true,
          alphabet: "",
        },
      },
      alphabets: getAlphabets(),
    };
  },
  computed: {
    pageData() {
      let paginationData = this.pagination;
      paginationData.search = this.search;
      paginationData.searchOption = "V.name";
      return paginationData;
    },
    getStyles() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return {
          maxWidth: "95%",
          margin: "0 auto",
        };
      } else {
        return {};
      }
    },
  },
  watch: {
    search() {
      this.getItems();
    },
    pagination: {
      handler: function () {
        this.getItems();
      },
      deep: true,
    },
  },
  methods: {
    getFormatedDate(date) {
      return new Date(date).toLocaleDateString();
    },
    getItems() {
      let _self = this;
      this.loading = "secondary";
      let url = parseParams(this.pageData);
      this.$axios.get("/admin/session/list?" + url)
        .then((response) => {
          _self.items = response.data.data;
          this.loading = false;
        })
        .catch(function () {
          this.loading = false;
        });
    },
    confirmDelete(item){
      this.dialog = true;
      this.session = item;
    },
    deleteItem(item) {
      const index = this.items.data.indexOf(item);
      let _self = this;
      _self.loading = "secondary";
      this.$axios.post("/admin/session/delete", {
        session_id: item.session_id,
      })
      .then((res) => {
        if (res.status) {
          _self.items.data.splice(index, 1);
          _self.dialog = false
        }
        _self.loading = false;
      })
      .catch(function () {
        _self.loading = false;
      });
    },
  },
};
</script>