var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"pa-0"},[_c('v-card-title',[_vm._v(" Session List "),_c('v-spacer'),_c('v-text-field',{staticClass:"pa-0",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"search":_vm.search,"items":_vm.items.data,"server-items-length":_vm.items.total,"options":_vm.pagination,"footer-props":{
        'items-per-page-options': [5,10,20,30,50,100,-1],
      },"loading":_vm.loading,"item-key":"name"},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.start_time",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.start_time).format('DD-MM-YYYY hh:mm A'))+" ")]}},{key:"item.end_time",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.end_time).format('DD-MM-YYYY hh:mm A'))+" ")]}},{key:"item.video",fn:function(ref){
      var item = ref.item;
return [(!item.is_live_meeting)?_c('v-btn',{attrs:{"color":"grey darken-2","small":"","dark":""},on:{"click":function($event){_vm.play_video = item.meeting_id_url}}},[_c('v-icon',[_vm._v("mdi-play")]),_vm._v(" Play ")],1):_c('span',[_vm._v(" - ")])]}},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"color":"grey darken-2","icon":"","dark":"","to":'/session/edit/'+item.session_id}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"color":"grey darken-2","icon":"","dark":""},on:{"click":function($event){return _vm.confirmDelete(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1),_c('v-btn',{attrs:{"color":"primary","fixed":"","fab":"","bottom":"","right":"","to":"/session/add"}},[_c('v-icon',[_vm._v("mdi-plus")])],1),(_vm.play_video)?_c('v-dialog',{attrs:{"persistent":"","width":"50%","max-width":"640px"},model:{value:(_vm.play_video),callback:function ($$v) {_vm.play_video=$$v},expression:"play_video"}},[_c('v-card',[_c('v-card-text',{staticClass:"pa-3 rounded-lg"},[_c('iframe',{attrs:{"src":_vm.play_video,"width":"100%","height":"360","frameborder":"0","allow":"autoplay; fullscreen","allowfullscreen":""}})]),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey darken-4","text":""},on:{"click":function($event){_vm.play_video = false}}},[_vm._v("Close")])],1)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-5"},[_vm._v("Are you sure delete "),_c('b',[_vm._v(_vm._s(_vm.session.title))]),_vm._v("?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey darken-4","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"red darken-4","text":""},on:{"click":function($event){return _vm.deleteItem(_vm.session)}}},[_vm._v("Delete")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }